import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { any, array, object } from 'prop-types';
import { useParams } from 'react-router-dom';

import * as reduxActions from '../../redux/actions';
import './investAdmin.scss';
import GoBack from '../../components/goBack/goBack';

import investmentAdminIcon from '../../assets/image/investmentAdminIcon.svg';

const InvestAdmin = ({
  actions,
  investmentClosingData,
  closeInvestmentClosingData,
  closingSummariesData,
  investInvestmentClosingData,
}) => {
  const { t } = useTranslation();
  const params = useParams();

  const [closingSummaries, setClosingSummaries] = useState([]);

  const changeInput = (index, fieldName) => (event) => {
    const newClosingSummaries = [...closingSummaries];
    const { value } = event.target;
    newClosingSummaries[index][fieldName] = value || 0;
    setClosingSummaries(newClosingSummaries);
  };

  useEffect(() => {
    actions.getInvestmentClosing({ id: params.investmentClosingId });
  }, []);

  useEffect(() => {
    if (closeInvestmentClosingData) {
      actions.getInvestmentClosing({ id: params.investmentClosingId });
      actions.cleanCloseInvestmentClosing();
    }
  }, [closeInvestmentClosingData]);

  useEffect(() => {
    if (investInvestmentClosingData) {
      actions.getInvestmentClosing({ id: params.investmentClosingId });
      actions.cleanInvestInvestmentClosing();
    }
  }, [investInvestmentClosingData]);

  useEffect(() => {
    if (closingSummariesData && closingSummariesData.length) {
      const newClosingSummaries = closingSummariesData.map(
        (closingSummaryItem) => {
          return {
            received: 0.0,
            id: closingSummaryItem.id,
          };
        }
      );
      setClosingSummaries(newClosingSummaries);
    }
  }, [closingSummariesData]);

  useEffect(() => {
    if (investmentClosingData && investmentClosingData.status !== 0) {
      actions.getClosingSummaries({ id: params.investmentClosingId });
    }
  }, [investmentClosingData]);

  const updateInvestmentClosing = () => {
    actions.updateInvestmentClosing({ id: params.investmentClosingId });
  };

  const isValid = () => {
    let valid = true;
    if (closingSummaries.length === 0) {
      valid = false;
    }

    closingSummaries.forEach((closingSummaryItem) => {
      if (!(closingSummaryItem.received && closingSummaryItem.received > 0)) {
        valid = false;
      }
    });

    return valid;
  };

  const invest = () => {
    const newClosingSummaries = closingSummaries.map((closingSummaryItem) => {
      const { received, id } = closingSummaryItem;
      return {
        received: parseFloat(received),
        id,
      };
    });
    actions.investInvestmentClosing({
      id: params.investmentClosingId,
      investBody: newClosingSummaries,
    });
  };

  return (
    <div className="investment-admin">
      <GoBack />
      <section className="hedings">
        <Col xs>
          <Row>
            <Col xs={12} lg={4} className="title-container">
              <h1>{t('investAdmin.investmentArea')}</h1>
              <p>{t('investAdmin.makeTheClosingDailyInvestment')}</p>
            </Col>
            <Col xs={12} lg={4}>
              <Row>
                {investmentClosingData && (
                  <div className="balance-card">
                    <Col xs={12}>
                      <Row>
                        <Col xs={12} className="title-container">
                          <span className="title">
                            {t('investAdmin.overallCumulative')}
                          </span>
                        </Col>
                        <Col xs={12} className="currency-container">
                          <span className="amount">
                            $ {investmentClosingData.amount}
                          </span>{' '}
                          <span className="currency">USD</span>
                        </Col>
                      </Row>
                    </Col>
                  </div>
                )}
              </Row>
              {investmentClosingData && investmentClosingData.status === 0 && (
                <Row center="xs" className="buttons">
                  <button
                    type="button"
                    className="cancel"
                    onClick={() => {
                      updateInvestmentClosing();
                    }}
                  >
                    {t('investAdmin.performClosing')}
                  </button>
                </Row>
              )}
            </Col>
            <Col xs={12} lg={4}>
              <img src={investmentAdminIcon} alt="investment-icon" />
            </Col>
          </Row>
        </Col>
      </section>
      {investmentClosingData && investmentClosingData.status !== 0 && (
        <>
          <section className="table-container">
            <h2>{t('investAdmin.investments')}</h2>
          </section>
          <div className="table-card">
            <table>
              <thead>
                <tr>
                  <th>{t('investAdmin.crypto')}</th>
                  <th>{t('investAdmin.name')}</th>
                  <th>{t('investAdmin.invest')}</th>
                  <th>{t('investAdmin.received')}</th>
                </tr>
              </thead>
              <tbody>
                {closingSummariesData &&
                  closingSummaries.length > 0 &&
                  closingSummariesData.map((closingSummaryItem, index) => {
                    return (
                      <tr>
                        <td className="cripto-row">
                          <img
                            src={`https://s3.amazonaws.com/bulltor.fluvip.com/cryptos/${closingSummaryItem.crytoCode}.svg`}
                            className="cripto-icon"
                            alt={closingSummaryItem.crytoName}
                          />
                          <p>{closingSummaryItem.crytoCode}</p>
                        </td>
                        <td>{closingSummaryItem.crytoName}</td>
                        <td>
                          $ {closingSummaryItem.amount}{' '}
                          <span className="flag-currency">USD</span>
                        </td>
                        {investmentClosingData.status === 1 && (
                          <td>
                            <div className="td-container">
                              <div className="input-container">
                                <input
                                  className="input-number"
                                  type="number"
                                  value={closingSummaries[index].received}
                                  onChange={changeInput(index, 'received')}
                                />
                                <span className="flag-currency">
                                  {closingSummaryItem.crytoCode}
                                </span>
                              </div>
                            </div>
                          </td>
                        )}
                        {investmentClosingData.status === 2 && (
                          <td>
                            $ {closingSummaryItem.received}{' '}
                            <span className="flag-currency">
                              {closingSummaryItem.crytoCode}
                            </span>
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {investmentClosingData && investmentClosingData.status === 1 && (
            <Row center="xs" className="buttons">
              <button
                type="button"
                className="cancel"
                disabled={!isValid()}
                onClick={() => {
                  invest();
                }}
              >
                {t('investAdmin.invest')}
              </button>
            </Row>
          )}
        </>
      )}
    </div>
  );
};

InvestAdmin.propTypes = {
  actions: any.isRequired,
  investmentClosingData: object,
  closeInvestmentClosingData: object,
  investInvestmentClosingData: object,
  closingSummariesData: array,
};

InvestAdmin.defaultProps = {
  investmentClosingData: null,
  closeInvestmentClosingData: null,
  closingSummariesData: null,
  investInvestmentClosingData: null,
};
const mapStateToProps = (state) => {
  const {
    investmentClosingData,
    closeInvestmentClosingData,
    closingSummariesData,
    investInvestmentClosingData,
  } = state.admin;

  return {
    investmentClosingData,
    closeInvestmentClosingData,
    closingSummariesData,
    investInvestmentClosingData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(reduxActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvestAdmin);
