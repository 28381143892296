import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { number } from 'prop-types';
import { useTranslation } from 'react-i18next';

import './processCard.scss';

const ProcessCard = ({ amount }) => {
  const { t } = useTranslation();

  return (
    <div className="process-card">
      <Col xs={12}>
        <Row>
          <Col xs={12} className="title-container">
            <span className="title">{t('processCard.investmentOf')}</span>
          </Col>
          <Col xs={10} xsOffset={1} className="currency-container">
            <span className="amount">$ {amount}</span>
            <span className="currency">USD</span>
          </Col>
          <Col xs={12} className="title-container">
            <span className="title">{t('processCard.inProcess')}</span>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

ProcessCard.propTypes = {
  amount: number,
};

ProcessCard.defaultProps = {
  amount: 0,
};

export default ProcessCard;
