import { combineReducers } from 'redux';

import user from './user.reducer';
import movement from './movement.reducer';
import company from './company.reducer';
import admin from './admin.reducer';
import crypto from './crypto.reducer';

const rootReducer = combineReducers({
  user,
  movement,
  company,
  admin,
  crypto,
});

export default rootReducer;
