import { handleResponse, authHeader } from '../../helpers';

export const login = ({ email, password }) => {
  const headers = { 'Content-Type': 'application/json' };
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    headers,
  };

  return fetch(`${process.env.REACT_APP_API_URL}login`, requestOptions).then(
    handleResponse
  );
};

export const getEmployeeSummaries = ({ page }) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };

  const query = `?page=${page}&limit=20`;

  return fetch(
    `${process.env.REACT_APP_API_URL}employee-summaries${query}`,
    requestOptions
  ).then(handleResponse);
};

export const getEmployee = ({ id }) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}employees/${id}`,
    requestOptions
  ).then(handleResponse);
};

export const getEmployeeInvestment = () => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}employee-investments`,
    requestOptions
  ).then(handleResponse);
};

export const createEmployeeInvestment = (employeeInvestmentBody) => {
  const headers = authHeader();
  headers['Content-Type'] = 'application/json';
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(employeeInvestmentBody),
    headers,
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}employee-investments`,
    requestOptions
  ).then(handleResponse);
};
