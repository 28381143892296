import React from 'react';
import { node, string } from 'prop-types';

import './modal.scss';

const Modal = ({ children, className }) => {
  return (
    <div className="modal-container">
      <div className={`modal modal_${className}`}>
        <div className="modal_body">{children}</div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: node.isRequired,
  className: string.isRequired,
};

export default Modal;
