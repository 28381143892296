import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { number, string } from 'prop-types';

import './balanceCard.scss';

const BalanceCard = ({ amount, title }) => {
  return (
    <div className="balance-card">
      <Col xs={12}>
        <Row>
          <Col xs={12} className="title-container">
            <span className="title">{title}</span>
          </Col>
          <Col xs={12} className="currency-container">
            <span className="amount">$ {amount}</span>{' '}
            <span className="currency">USD</span>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

BalanceCard.propTypes = {
  amount: number,
  title: string,
};

BalanceCard.defaultProps = {
  amount: 0,
  title: '',
};

export default BalanceCard;
