import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { any, object, string } from 'prop-types';

import * as reduxActions from '../../redux/actions';
import Modal from '../../components/modal/modal';
import CreateUser from '../../components/createUser/createUser';
import UpdateUser from '../../components/updateUser/updateUser';
import ConfirmComponent from '../../components/confirmComponent/confirmComponent';
// import BalanceCard from '../../components/balanceCard/balanceCard';

import userimg from '../../assets/image/userdefult.svg';
import trash from '../../assets/image/trash.svg';
import pencil from '../../assets/image/pencil.svg';
import left from '../../assets/image/left.svg';
import right from '../../assets/image/right.svg';
import plus from '../../assets/image/plus.svg';
// import upload from '../../assets/image/upload.svg';

import './homeCompany.scss';

const HomeCompany = ({
  actions,
  user,
  newUser,
  usersData,
  updatedUser,
  deletedUser,
}) => {
  const { t } = useTranslation();
  const [displayCreateModal, setDisplayCreateModal] = useState('');
  const [displayUpdateModal, setDisplayUpdateModal] = useState('');
  const [displayConfirm, setDisplayConfirm] = useState('');
  const [updateUser, setUpdateUser] = useState();
  const [deleteUser, setDeleteUser] = useState();
  const [page, setPage] = useState(1);

  const pageSize = (usersData && usersData.pageSize) || 0;
  const totalItems = (usersData && usersData.total) || 0;

  const numberOfPages = Math.ceil(totalItems / pageSize);

  const pages = Array.from(Array(numberOfPages || 1).keys());

  const getUsers = (newPage) => {
    actions.getUsers({ page: newPage });
  };

  useEffect(() => {
    actions.getUser();
  }, []);

  useEffect(() => {
    if (page) {
      getUsers(page);
    }
  }, [page]);

  useEffect(() => {
    if (newUser) {
      getUsers(1);
    }
  }, [newUser]);

  useEffect(() => {
    if (updatedUser) {
      actions.cleanUpdatedUser();
      getUsers(1);
    }
  }, [updatedUser]);

  useEffect(() => {
    if (deletedUser) {
      actions.cleanDeletedUser();
      getUsers(1);
    }
  }, [deletedUser]);

  const showCreateModal = (show) => {
    setDisplayCreateModal(show);
  };

  const showUpdateModal = (show, userItem) => {
    setDisplayUpdateModal(show);
    setUpdateUser(userItem);
  };

  const handleCreate = async (body) => {
    setDisplayCreateModal(false);
    actions.createUser(body);
  };

  const handleDelete = async (id) => {
    setDisplayConfirm(false);
    actions.deleteUser(id);
  };

  const handleUpdate = async (id, body) => {
    setDisplayUpdateModal(false);
    actions.updateUser(id, body);
  };

  return (
    <div className="home-company">
      {displayConfirm && (
        <Modal className="xs">
          <ConfirmComponent
            onCancel={() => {
              setDisplayConfirm(false);
            }}
            onConfirm={() => {
              handleDelete(deleteUser.id);
            }}
            confirmText={`${t('createUser.areYouSureYouWantToDelete')} ${
              deleteUser.name
            } ${deleteUser.lastname}?`}
          />
        </Modal>
      )}
      {displayCreateModal && (
        <Modal className="lg">
          <CreateUser
            onCancel={() => {
              showCreateModal(false);
            }}
            onCreate={handleCreate}
          />
        </Modal>
      )}
      {displayUpdateModal && (
        <Modal className="lg">
          <UpdateUser
            onCancel={() => {
              setDisplayUpdateModal(false);
            }}
            updateUser={updateUser}
            onSave={handleUpdate}
          />
        </Modal>
      )}
      <section className="hedings">
        <Col xs>
          <Row>
            <Col xs={12} lg={4} className="title-container">
              <div className="welcome">
                <h1>{t('homeCompany.welcome')}</h1>
                <span className="line" />
                <p className="profile">{user && user.user && user.user.name}</p>
              </div>
              <p className="sub-title light">
                {t('homeCompany.fromThisPanel')}
              </p>
              <p className="sub-title light">
                {t('homeCompany.assetsInYourAccount')}
              </p>
            </Col>
            {/* <Col xs={12} lg={4} className="cards-container">
              <AmountCard />
              <BalanceCard />
            </Col> */}
          </Row>
        </Col>
      </section>
      <section className="table-container">
        <h2>{t('homeCompany.usersManagement')}</h2>
      </section>
      <div className="buttons">
        <button
          type="button"
          onClick={() => {
            showCreateModal(true);
          }}
        >
          {t('homeCompany.addUser')} <img src={plus} alt="plus" />
        </button>
        {/* <button type="button">
          {t('homeCompany.bulkLoad')} <img src={upload} alt="upload" />
        </button> */}
      </div>
      <div className="table-card">
        <table>
          <thead>
            <tr>
              <th>{t('homeCompany.table.name')}</th>
              <th>{t('homeCompany.table.lastName')}</th>
              <th>{t('homeCompany.table.email')}</th>
              <th>{t('homeCompany.table.monthlyAllowance')}</th>
              <th>{t('homeCompany.table.actions')}</th>
            </tr>
          </thead>
          <tbody>
            {usersData &&
              usersData.docs &&
              usersData.docs.map((userItem) => {
                return (
                  <tr key={userItem.id}>
                    <td>
                      <div className="name">
                        <img
                          src={userimg}
                          className="cripto-icon"
                          alt="userimg"
                        />
                        <p>{userItem.name}</p>
                      </div>
                    </td>
                    <td>{userItem.lastname}</td>
                    <td>{userItem.email}</td>
                    <td>
                      $ {userItem.monthlyAllowance}{' '}
                      <span className="flag-currency">USD</span>
                    </td>
                    <td className="actions">
                      <button
                        type="button"
                        className="action"
                        onClick={() => {
                          setDeleteUser(userItem);
                          setDisplayConfirm(true);
                        }}
                      >
                        <img src={trash} alt="trash" />
                      </button>

                      <button
                        type="button"
                        className="action"
                        onClick={() => {
                          showUpdateModal(true, userItem);
                        }}
                      >
                        <img src={pencil} alt="pencil" />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {usersData && usersData.total > 0 && (
        <div className="paginator">
          {page > 1 && (
            <img
              src={left}
              className="left"
              alt="left"
              onClick={() => {
                setPage(page - 1);
              }}
              aria-hidden="true"
            />
          )}
          {pages.map((pageItem) => {
            return (
              <div
                key={pageItem}
                className={`item ${pageItem + 1 === page ? 'selected' : ''}`}
                onClick={() => {
                  if (pageItem + 1 !== page) {
                    setPage(pageItem + 1);
                  }
                }}
                aria-hidden="true"
              >
                {pageItem + 1}
              </div>
            );
          })}
          {page < numberOfPages && (
            <img
              src={right}
              className="right"
              alt="right"
              onClick={() => {
                setPage(page + 1);
              }}
              aria-hidden="true"
            />
          )}
        </div>
      )}
    </div>
  );
};
HomeCompany.propTypes = {
  actions: any.isRequired,
  user: object,
  newUser: object,
  usersData: object,
  updatedUser: string,
  deletedUser: string,
};

HomeCompany.defaultProps = {
  user: null,
  newUser: null,
  usersData: null,
  updatedUser: null,
  deletedUser: null,
};
const mapStateToProps = (state) => {
  const { user } = state.user;
  const { newUser, usersData, updatedUser, deletedUser } = state.company;

  return {
    user,
    newUser,
    usersData,
    updatedUser,
    deletedUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(reduxActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeCompany);
