import { handleResponse, authHeader } from '../../helpers';

export const getCryptos = () => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };

  const query = `?page=1&limit=100`;

  return fetch(
    `${process.env.REACT_APP_API_URL}cryptos${query}`,
    requestOptions
  ).then(handleResponse);
};
