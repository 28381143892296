export function authHeader() {
  // return authorization header with jwt token
  const token = localStorage.getItem('token');
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
}

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && response.ok && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 400) {
        // auto logout if 401 response returned from api
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.reload(true);
      }
      const messageJson = JSON.parse(text);
      const errorArray = {
        status: response.status,
        text: (data && data.data) || messageJson.data,
      };
      const error = errorArray;
      return Promise.reject(error);
    }

    return data;
  });
}
