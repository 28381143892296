import {
  GENERAL_ERROR,
  RESET_ALL,
  SHOW_GENERAL_LOADING,
  HIDE_GENERAL_LOADING,
} from '../action-types';

const initialState = {
  error: null,
  generalLoading: false,
};

// eslint-disable-next-line default-param-last
const movement = (state = initialState, action) => {
  switch (action.type) {
    case GENERAL_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }

    case SHOW_GENERAL_LOADING: {
      return {
        ...state,
        generalLoading: true,
      };
    }

    case HIDE_GENERAL_LOADING: {
      return {
        ...state,
        generalLoading: false,
      };
    }

    case RESET_ALL: {
      return initialState;
    }

    default:
      return state;
  }
};

export default movement;
