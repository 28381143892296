import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';

import './createCompany.scss';

const CreateCompany = ({ onCancel, onCreate }) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [userLimit, setUserLimit] = useState('');
  const [email, setEmail] = useState('');
  const [withdrawalDeadline, setWithdrawalDeadline] = useState('');
  const [password, setPassword] = useState('');

  const changeInput = (inputSet) => (event) => {
    inputSet(event.target.value);
  };

  const handleSubmit = () => {
    onCreate({
      name,
      userLimit,
      email,
      withdrawalDeadline,
      password,
    });
  };

  return (
    <div className="create-company">
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <h1>{t('createCompany.createCompany')}</h1>
          </Col>
          <Col xs={12} lg={4} lgOffset={2}>
            <div className="input-container">
              <label htmlFor="name">{t('createCompany.name')}</label>
              <input
                type="text"
                value={name}
                onChange={changeInput(setName)}
                placeholder={t('createCompany.name')}
              />
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className="input-container">
              <label htmlFor="userLimit">{t('createCompany.userLimit')}</label>
              <input
                type="number"
                value={userLimit}
                onChange={changeInput(setUserLimit)}
                placeholder={t('createCompany.userLimit')}
              />
            </div>
          </Col>
          <Col xs={12} lg={4} lgOffset={2}>
            <div className="input-container">
              <label htmlFor="email">{t('createCompany.email')}</label>
              <input
                type="email"
                value={email}
                onChange={changeInput(setEmail)}
                placeholder={t('createCompany.email')}
              />
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className="input-container">
              <label htmlFor="withdrawalDeadline">
                {t('createCompany.withdrawalDeadline')}
              </label>
              <input
                type="number"
                value={withdrawalDeadline}
                onChange={changeInput(setWithdrawalDeadline)}
                placeholder={t('createCompany.withdrawalDeadline')}
              />
            </div>
          </Col>
          <Col xs={12} lg={8} lgOffset={2}>
            <div className="input-container">
              <label htmlFor="password">{t('createCompany.password')}</label>
              <input
                type="password"
                value={password}
                onChange={changeInput(setPassword)}
                placeholder={t('createCompany.password')}
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="buttons-container">
              <button type="button" className="cancel" onClick={onCancel}>
                {t('updateProfile.cancel')}
              </button>
              <button
                type="button"
                disabled={
                  !(
                    name &&
                    userLimit &&
                    email &&
                    withdrawalDeadline &&
                    password
                  )
                }
                onClick={() => {
                  handleSubmit();
                }}
                className="save"
              >
                {t('updateProfile.save')}
              </button>
            </div>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

CreateCompany.propTypes = {
  onCancel: func.isRequired,
  onCreate: func.isRequired,
};

export default CreateCompany;
