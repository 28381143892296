import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-flexbox-grid';
import { useNavigate } from 'react-router-dom';

import left from '../../assets/image/left.svg';

import './goBack.scss';

const GoBack = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="go-back">
      <Col xs={12}>
        <Row>
          <Col
            xs={12}
            className="container"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={left} className="left" alt="left" />
            <p>{t('goBack')}</p>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default GoBack;
