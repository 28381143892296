import React, { useState, useEffect } from 'react';
import { any, object } from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reduxActions from '../../redux/actions';

import userdefult from '../../assets/image/userdefult.svg';

import './login.scss';

const ADMIN = 'admin';
const COMPANY = 'company';
const EMPLOYEE = 'employee';

const Login = ({ actions, user }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (user && user.token) {
      switch (user.role) {
        case ADMIN:
          navigate(`/home-admin`);
          break;
        case COMPANY:
          navigate(`/home-company`);
          break;
        case EMPLOYEE:
          navigate(`/`);
          break;
        default:
          break;
      }
    }
  }, [user]);

  const changeInput = (inputSet) => (event) => {
    inputSet(event.target.value);
  };

  const handleSubmit = () => {
    actions.login({
      email,
      password,
    });
  };

  return (
    <div className="login">
      <section className="hedings">
        <Col xs>
          <Row>
            <Col xs={12} lg={8} lgOffset={2} className="title-container">
              <h2>{t('login.signIn')}</h2>
              <p />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={8} lgOffset={2} className="login-container">
              <Row>
                <Col xs={12} className="flex-center">
                  <img src={userdefult} className="user-logo" alt="user-logo" />
                </Col>
                <Col xs={12} lg={6} lgOffset={3}>
                  <div className="input-container">
                    <label htmlFor="email">{t('login.email')}</label>
                    <input
                      name="email"
                      type="email"
                      value={email}
                      onChange={changeInput(setEmail)}
                      placeholder={t('login.email')}
                    />
                  </div>
                  <div className="input-container">
                    <label htmlFor="password">{t('login.password')}</label>
                    <input
                      type="password"
                      value={password}
                      onChange={changeInput(setPassword)}
                      placeholder={t('login.password')}
                    />
                  </div>
                </Col>
                <Col xs={12} className="flex-center">
                  <button
                    disabled={!(email && password)}
                    type="button"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {t('login.login')}
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </section>
    </div>
  );
};

Login.propTypes = {
  actions: any.isRequired,
  user: object,
};

Login.defaultProps = {
  user: null,
};
const mapStateToProps = (state) => {
  const { user } = state.user;

  return {
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(reduxActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
