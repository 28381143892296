import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-flexbox-grid';
import { number } from 'prop-types';

import './amountCard.scss';

const AmountCard = ({ totalInvested }) => {
  const { t } = useTranslation();

  return (
    <div className="amount-card">
      <Col xs={12}>
        <Row>
          <Col xs={12} className="title-container">
            <span className="title">{t('amountCard.totalInvested')}</span>
          </Col>
          <Col xs={12} className="currency-container first-child">
            <span className="amount">$ {totalInvested}</span>{' '}
            <span className="currency">USD</span>
          </Col>
          {/* <Col xs={12} className="title-container">
            <span className="title">{t('amountCard.profits')}</span>
          </Col>
          <Col xs={12} className="currency-container">
            <span className="amount">$ {profits}</span>{' '}
            <span className="currency">USD</span>
          </Col> */}
        </Row>
      </Col>
    </div>
  );
};

AmountCard.propTypes = {
  totalInvested: number,
  // profits: number,
};

AmountCard.defaultProps = {
  totalInvested: 0,
  // profits: 0,
};

export default AmountCard;
