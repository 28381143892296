import * as cryptoService from '../services/crypto.service';
import {
  GENERAL_ERROR,
  SHOW_GENERAL_LOADING,
  HIDE_GENERAL_LOADING,
  SET_CRYTOS_DATA,
} from '../action-types';

const generalError = (error) => {
  return { type: GENERAL_ERROR, error };
};

const showLoading = () => {
  return { type: SHOW_GENERAL_LOADING };
};
const hideLoading = () => {
  return { type: HIDE_GENERAL_LOADING };
};

const setCrytos = (crytos) => {
  return { type: SET_CRYTOS_DATA, crytos };
};

export const getCryptos = () => {
  return (dispatch) => {
    dispatch(showLoading());
    cryptoService.getCryptos().then(
      (body) => {
        const { data } = body;
        dispatch(setCrytos(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};
