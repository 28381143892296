import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { any, object, string } from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import * as reduxActions from '../../redux/actions';
import Modal from '../../components/modal/modal';
import CreateCompany from '../../components/createCompany/createCompany';
import UpdateCompany from '../../components/updateCompany/updateCompany';
import ConfirmComponent from '../../components/confirmComponent/confirmComponent';
import BalanceCard from '../../components/balanceCard/balanceCard';

import trash from '../../assets/image/trash.svg';
import eyeaction from '../../assets/image/eyeaction.svg';
import payicon from '../../assets/image/payicon.svg';
import pencil from '../../assets/image/pencil.svg';
import left from '../../assets/image/left.svg';
import right from '../../assets/image/right.svg';
import plus from '../../assets/image/plus.svg';
import homeAdminIcon from '../../assets/image/homeAdminIcon.svg';
// import upload from '../../assets/image/upload.svg';

import './homeAdmin.scss';

const INVESTMENT_TAB = 0;
const COMPANY_TAB = 1;

const HomeAdmin = ({
  actions,
  newCompany,
  companiesData,
  investmentData,
  updatedCompany,
  deletedCompany,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [displayCreateModal, setDisplayCreateModal] = useState('');
  const [displayUpdateModal, setDisplayUpdateModal] = useState('');
  const [displayPayModal, setDisplayPayModal] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [displayConfirm, setDisplayConfirm] = useState('');
  const [payCompany, setPayCompany] = useState();
  const [updateCompany, setUpdateCompany] = useState();
  const [deleteCompany, setDeleteCompany] = useState();
  const [companyPage, setCompanyPage] = useState(1);

  const [investmentPage, setInvestmentPage] = useState(1);

  const [investmentActive, setInvestmentActive] = useState(null);

  const investmentPageSize = (investmentData && investmentData.pageSize) || 0;
  const investmentTotalItems = (investmentData && investmentData.total) || 0;
  const numberOfInvestmentPages = Math.ceil(
    investmentTotalItems / investmentPageSize
  );

  const investmentPages = Array.from(
    Array(numberOfInvestmentPages || 1).keys()
  );

  const companyPageSize = (companiesData && companiesData.pageSize) || 0;
  const companyTotalItems = (companiesData && companiesData.total) || 0;

  const companyHeaders = [
    'Nombre',
    'Email',
    'Límite de usuarios',
    'Tiempo de inversión',
    'Nómina',
    'Acciones',
  ];
  const investmentHeaders = [
    'Fecha',
    'Cantidad a Invertir',
    'Estado',
    'Acciones',
  ];

  const numberOfCompanyPages = Math.ceil(companyTotalItems / companyPageSize);

  const companyPages = Array.from(Array(numberOfCompanyPages || 1).keys());

  const getStatusName = (status) => {
    switch (status) {
      case 0:
        return 'Activo';
      case 1:
        return 'Pendiente';

      default:
        return 'Invertido';
    }
  };

  const getCompanies = (newPage) => {
    actions.getCompanies({ page: newPage });
  };

  const getInvestment = (newPage) => {
    actions.getInvestmentClosings({ page: newPage });
  };

  useEffect(() => {
    actions.getUser();
  }, []);

  useEffect(() => {
    if (companyPage) {
      getCompanies(companyPage);
    }
  }, [companyPage]);

  useEffect(() => {
    if (investmentPage) {
      getInvestment(investmentPage);
    }
  }, [investmentPage]);

  useEffect(() => {
    if (investmentData && investmentData.docs && investmentData.docs.length) {
      setInvestmentActive(investmentData.docs[0]);
    }
  }, [investmentData]);

  useEffect(() => {
    if (newCompany) {
      getCompanies(1);
    }
  }, [newCompany]);

  useEffect(() => {
    if (updatedCompany) {
      actions.cleanUpdatedCompany();
      getCompanies(1);
    }
  }, [updatedCompany]);

  useEffect(() => {
    if (deletedCompany) {
      actions.cleanDeletedCompany();
      getCompanies(1);
    }
  }, [deletedCompany]);

  const showCreateModal = (show) => {
    setDisplayCreateModal(show);
  };

  const showPayModal = (show, companyItem) => {
    setDisplayPayModal(show);
    setPayCompany(companyItem);
  };

  const showUpdateModal = (show, companyItem) => {
    setDisplayUpdateModal(show);
    setUpdateCompany(companyItem);
  };

  const handleCreate = async (body) => {
    setDisplayCreateModal(false);
    actions.createCompany(body);
  };

  const handleDelete = async (id) => {
    setDisplayConfirm(false);
    actions.deleteCompany(id);
  };

  const handleUpdate = async (id, body) => {
    setDisplayUpdateModal(false);
    actions.updateCompany(id, body);
  };

  const handlePay = async (id) => {
    setDisplayPayModal(false);
    actions.payCompany(id);
  };

  return (
    <div className="home-admin">
      {displayConfirm && (
        <Modal className="xs">
          <ConfirmComponent
            onCancel={() => {
              setDisplayConfirm(false);
            }}
            onConfirm={() => {
              handleDelete(deleteCompany.id);
            }}
            confirmText={`${t('createCompany.areYouSureYouWantToDelete')} ${
              deleteCompany.name
            }?`}
          />
        </Modal>
      )}
      {displayCreateModal && (
        <Modal className="lg">
          <CreateCompany
            onCancel={() => {
              showCreateModal(false);
            }}
            onCreate={handleCreate}
          />
        </Modal>
      )}
      {displayUpdateModal && (
        <Modal className="lg">
          <UpdateCompany
            onCancel={() => {
              setDisplayUpdateModal(false);
            }}
            updateCompany={updateCompany}
            onSave={handleUpdate}
          />
        </Modal>
      )}
      {displayPayModal && (
        <Modal className="xs">
          <ConfirmComponent
            onCancel={() => {
              setDisplayPayModal(false);
            }}
            onConfirm={() => {
              handlePay(payCompany.id);
            }}
            confirmText={`${t('createCompany.areYouSureYouWantToPay')} ${
              payCompany.name
            }?`}
          />
        </Modal>
      )}
      <section className="hedings">
        <Col xs>
          <Row middle="xs">
            <Col xs={12} lg={5} className="title-container">
              <div className="welcome">
                <h1>Bienvenido</h1>
              </div>
              <p className="sub-title light">
                Aquí puedes gestionar tus empresas y su presupuesto mensual.
              </p>
            </Col>
            <Col xs={12} lg={3} className="cards-container">
              {investmentActive && (
                <BalanceCard
                  amount={investmentActive.amount}
                  title="Balance de Inversión"
                />
              )}
            </Col>
            <Col xs={12} lg={3} className="cards-container">
              <img src={homeAdminIcon} alt="homeAdmin-icon" />
            </Col>
          </Row>
        </Col>
      </section>
      <section className="table-container">
        <h2>Gestión de empresas</h2>
      </section>
      <div className="buttons">
        {tabIndex === COMPANY_TAB && (
          <button
            type="button"
            onClick={() => {
              showCreateModal(true);
            }}
          >
            Agregar empresa <img src={plus} alt="plus" />
          </button>
        )}
        {/* <button type="button">
          {t('homeCompany.bulkLoad')} <img src={upload} alt="upload" />
        </button> */}
      </div>
      <div className="tabs">
        <button
          type="button"
          onClick={() => setTabIndex(0)}
          className={tabIndex === INVESTMENT_TAB ? 'selected' : ''}
        >
          Cierres
        </button>
        <button
          type="button"
          onClick={() => setTabIndex(1)}
          className={tabIndex === COMPANY_TAB ? 'selected' : ''}
        >
          Empresas
        </button>
      </div>
      <div className="table-card">
        <table>
          <thead>
            <tr>
              {(tabIndex === INVESTMENT_TAB
                ? investmentHeaders
                : companyHeaders
              ).map((v) => (
                <th>{v}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tabIndex === INVESTMENT_TAB &&
              investmentData &&
              investmentData.docs &&
              investmentData.docs.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{moment.utc(item.startDate).format('DD-MM-YYYY')}</td>
                    <td>$ {item.amount} USD</td>
                    <td>{getStatusName(item.status)}</td>
                    <td className="actions">
                      <button
                        type="button"
                        className="action"
                        onClick={() => {
                          navigate(`/admin-investment/${item.id}`);
                        }}
                      >
                        <img src={eyeaction} alt="eyeaction" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            {tabIndex === COMPANY_TAB &&
              companiesData &&
              companiesData.docs &&
              companiesData.docs.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className="name">
                        <p>{item.name}</p>
                      </div>
                    </td>
                    <td>{item.email}</td>
                    <td>{item.userLimit}</td>
                    <td>{item.withdrawalDeadline}</td>
                    <td>{item.payroll}</td>
                    <td className="actions">
                      <button
                        type="button"
                        className="action"
                        onClick={() => {
                          setDeleteCompany(item);
                          setDisplayConfirm(true);
                        }}
                      >
                        <img src={trash} alt="trash" />
                      </button>

                      <button
                        type="button"
                        className="action"
                        onClick={() => {
                          showUpdateModal(true, item);
                        }}
                      >
                        <img src={pencil} alt="pencil" />
                      </button>
                      <button
                        type="button"
                        className="action"
                        title={t('createCompany.pay')}
                        onClick={() => {
                          showPayModal(true, item);
                        }}
                      >
                        <img src={payicon} alt="payicon" />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {tabIndex === COMPANY_TAB && companiesData && companiesData.total > 0 && (
        <div className="paginator">
          {companyPage > 1 && (
            <img
              src={left}
              className="left"
              alt="left"
              onClick={() => {
                setCompanyPage(companyPage - 1);
              }}
              aria-hidden="true"
            />
          )}
          {companyPages.map((pageItem) => {
            return (
              <div
                key={pageItem}
                className={`item ${
                  pageItem + 1 === companyPage ? 'selected' : ''
                }`}
                onClick={() => {
                  if (pageItem + 1 !== companyPage) {
                    setCompanyPage(pageItem + 1);
                  }
                }}
                aria-hidden="true"
              >
                {pageItem + 1}
              </div>
            );
          })}
          {companyPage < numberOfCompanyPages && (
            <img
              src={right}
              className="right"
              alt="right"
              onClick={() => {
                setCompanyPage(companyPage + 1);
              }}
              aria-hidden="true"
            />
          )}
        </div>
      )}
      {tabIndex === INVESTMENT_TAB &&
        investmentData &&
        investmentData.total > 0 && (
          <div className="paginator">
            {investmentPage > 1 && (
              <img
                src={left}
                className="left"
                alt="left"
                onClick={() => {
                  setInvestmentPage(investmentPage - 1);
                }}
                aria-hidden="true"
              />
            )}
            {investmentPages.map((pageItem) => {
              return (
                <div
                  key={pageItem}
                  className={`item ${
                    pageItem + 1 === investmentPage ? 'selected' : ''
                  }`}
                  onClick={() => {
                    if (pageItem + 1 !== investmentPage) {
                      setInvestmentPage(pageItem + 1);
                    }
                  }}
                  aria-hidden="true"
                >
                  {pageItem + 1}
                </div>
              );
            })}
            {investmentPage < numberOfInvestmentPages && (
              <img
                src={right}
                className="right"
                alt="right"
                onClick={() => {
                  setInvestmentPage(investmentPage + 1);
                }}
                aria-hidden="true"
              />
            )}
          </div>
        )}
    </div>
  );
};
HomeAdmin.propTypes = {
  actions: any.isRequired,
  newCompany: object,
  companiesData: object,
  investmentData: object,
  updatedCompany: string,
  deletedCompany: string,
};

HomeAdmin.defaultProps = {
  newCompany: null,
  companiesData: null,
  investmentData: null,
  updatedCompany: null,
  deletedCompany: null,
};
const mapStateToProps = (state) => {
  const {
    newCompany,
    companiesData,
    investmentData,
    updatedCompany,
    deletedCompany,
  } = state.admin;

  return {
    newCompany,
    companiesData,
    investmentData,
    updatedCompany,
    deletedCompany,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(reduxActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeAdmin);
