import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-flexbox-grid';

import bulltorLogo from '../../assets/image/bulltorLogo.svg';
import instagram from '../../assets/image/instagram.svg';
import linkedin from '../../assets/image/linkedin.svg';
import facebook from '../../assets/image/facebook.svg';
import youtube from '../../assets/image/youtube.svg';

import './footer.scss';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="container">
        <Col xs={12}>
          <Row>
            <Col className="logo-container">
              <img src={bulltorLogo} className="logo" alt="logo" />
            </Col>
            <Col xs={12} lg>
              <Row>
                <Col lg={12} className="links">
                  <a href="/contact">{t('footer.contactUs')}</a>
                  <a href="/contact">{t('footer.information')}</a>
                  <a href="/contact">{t('footer.aboutUs')}</a>
                  <a href="/contact">{t('footer.socialMedia')}</a>
                  <a href="/contact">{t('footer.workWithUs')}</a>
                  <a href="/contact">{t('footer.api')}</a>
                </Col>
                <Col lg={12} className="social-container">
                  <a href="https://www.facebook.com/">
                    <img
                      src={instagram}
                      className="instagram-icon"
                      alt="instagram"
                    />
                  </a>
                  <a href="https://www.facebook.com/">
                    <img
                      src={linkedin}
                      className="linkedin-icon"
                      alt="linkedin"
                    />
                  </a>
                  <a href="https://www.facebook.com/">
                    <img
                      src={facebook}
                      className="facebook-icon"
                      alt="facebook"
                    />
                  </a>
                  <a href="https://www.facebook.com/">
                    <img src={youtube} className="youtube-icon" alt="youtube" />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </div>
    </footer>
  );
};

export default Footer;
