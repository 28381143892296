import {
  RESET_ALL,
  SET_NEW_USER,
  SET_USER_DATA,
  SET_UPDATED_USER,
  SET_DELETED_USER,
} from '../action-types';

const initialState = {
  newUser: null,
  updatedUser: null,
  deletedUser: null,
  usersData: null,
};

// eslint-disable-next-line default-param-last
const users = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_USER: {
      return {
        ...state,
        newUser: action.newUser,
      };
    }

    case SET_UPDATED_USER: {
      return {
        ...state,
        updatedUser: action.updatedUser,
      };
    }

    case SET_DELETED_USER: {
      return {
        ...state,
        deletedUser: action.deletedUser,
      };
    }

    case SET_USER_DATA: {
      return {
        ...state,
        usersData: action.usersData,
      };
    }

    case RESET_ALL: {
      return initialState;
    }

    default:
      return state;
  }
};

export default users;
