import * as companyService from '../services/company.service';
import {
  GENERAL_ERROR,
  SHOW_GENERAL_LOADING,
  HIDE_GENERAL_LOADING,
  SET_NEW_USER,
  SET_USER_DATA,
  SET_UPDATED_USER,
  SET_DELETED_USER,
} from '../action-types';

const generalError = (error) => {
  return { type: GENERAL_ERROR, error };
};

const showLoading = () => {
  return { type: SHOW_GENERAL_LOADING };
};

const hideLoading = () => {
  return { type: HIDE_GENERAL_LOADING };
};

const setNewUser = (newUser) => {
  return { type: SET_NEW_USER, newUser };
};

const setUpdatedUser = (updatedUser) => {
  return { type: SET_UPDATED_USER, updatedUser };
};

const setDeletedUser = (deletedUser) => {
  return { type: SET_DELETED_USER, deletedUser };
};

const setUsers = (usersData) => {
  return { type: SET_USER_DATA, usersData };
};

export const createUser = ({
  name,
  lastname,
  email,
  monthlyAllowance,
  password,
}) => {
  return (dispatch) => {
    dispatch(showLoading());
    companyService
      .createUser({
        name,
        lastname,
        email,
        monthlyAllowance,
        password,
      })
      .then(
        (body) => {
          const { data } = body;
          dispatch(setNewUser(data));
          dispatch(hideLoading());
        },
        (error) => {
          dispatch(generalError(error));
          dispatch(hideLoading(error));
        }
      );
  };
};

export const updateUser = (
  id,
  { name, lastname, email, monthlyAllowance, password }
) => {
  return (dispatch) => {
    dispatch(showLoading());
    companyService
      .updateUser(id, {
        name,
        lastname,
        email,
        monthlyAllowance,
        password,
      })
      .then(
        (body) => {
          const { data } = body;
          dispatch(setUpdatedUser(data));
          dispatch(hideLoading());
        },
        (error) => {
          dispatch(generalError(error));
          dispatch(hideLoading(error));
        }
      );
  };
};

export const deleteUser = (id) => {
  return (dispatch) => {
    dispatch(showLoading());
    companyService.deleteUser(id).then(
      (body) => {
        const { data } = body;
        dispatch(setDeletedUser(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};

export const getUsers = ({ page }) => {
  return (dispatch) => {
    dispatch(showLoading());
    companyService.getUsers({ page }).then(
      (body) => {
        const { data } = body;
        dispatch(setUsers(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};

export const cleanUpdatedUser = () => {
  return (dispatch) => {
    dispatch(setUpdatedUser(null));
  };
};

export const cleanDeletedUser = () => {
  return (dispatch) => {
    dispatch(setDeletedUser(null));
  };
};
