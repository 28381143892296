import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { any, object } from 'prop-types';

import * as reduxActions from '../../redux/actions';
import navImg from '../../assets/image/navbar.svg';
import userdefult from '../../assets/image/userdefult.svg';
import bulltorLogo from '../../assets/image/bulltorLogo.svg';

import './header.scss';

const Header = ({ actions, user }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    actions.getUser();
  }, []);

  const logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.reload(true);
  };

  return (
    <header className="header">
      <img src={navImg} className="background" alt="background" />
      <div className="header-container">
        <div className="container">
          <img src={bulltorLogo} className="logo" alt="logo" />
          <div className="dropdown">
            <img
              src={userdefult}
              className="user-logo dropbtn"
              alt="user logo"
            />
            <div className="dropdown-content">
              {user && (user.role === 'company' || user.role === 'employee') && (
                <div
                  aria-hidden="true"
                  className="sub-title light"
                  onClick={() => {
                    navigate(`/update-profile`);
                  }}
                >
                  {t('header.editProfile')}
                </div>
              )}
              <div
                aria-hidden="true"
                className="sub-title light"
                onClick={() => {
                  logOut();
                }}
              >
                {t('header.logOut')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  actions: any.isRequired,
  user: object,
};

Header.defaultProps = {
  user: null,
};
const mapStateToProps = (state) => {
  const { user } = state.user;

  return {
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(reduxActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
