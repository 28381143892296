import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { object, any, string } from 'prop-types';
import { useNavigate } from 'react-router-dom';

import * as reduxActions from '../../redux/actions';
import userdefult from '../../assets/image/userdefult.svg';

import './updateProfile.scss';
import GoBack from '../../components/goBack/goBack';

const UpdateProfile = ({ actions, user, updatedUser, updatedCompany }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  useEffect(() => {
    actions.getUser();
  }, []);

  useEffect(() => {
    if (updatedUser) {
      const newUser = user;
      newUser.user.name = name;
      newUser.user.lastname = lastname;
      localStorage.setItem('user', JSON.stringify(newUser));
      actions.cleanUpdatedUser();
      navigate(-1);
    }
  }, [updatedUser]);

  useEffect(() => {
    if (updatedCompany) {
      const newUser = user;
      newUser.user.name = name;
      localStorage.setItem('user', JSON.stringify(newUser));
      actions.cleanUpdatedCompany();
      navigate(-1);
    }
  }, [updatedCompany]);

  useEffect(() => {
    if (user) {
      setName(user.user.name);
      setEmail(user.user.email);
      if (user.role === 'employee') {
        setLastname(user.user.lastname);
      }
    }
  }, [user]);

  const changeInput = (inputSet) => (event) => {
    inputSet(event.target.value);
  };

  const handleSubmit = () => {
    const body = {
      name,
    };
    if (password) {
      body['password'] = password;
    }
    if (user.role === 'employee') {
      body['lastname'] = lastname;
      actions.updateUser(user.user.id, body);
    }
    if (user.role === 'company') {
      actions.updateCompany(user.user.id, body);
    }
  };

  return (
    <div className="update-profile">
      <GoBack />
      <section className="hedings">
        <Col xs>
          <Row>
            <Col xs={12} lg={12} className="title-container">
              <h1>{t('updateProfile.profile')}</h1>
              <p>{t('updateProfile.updateOptions')}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={12} className="login-container">
              <h1>{t('updateProfile.user')}</h1>
              <div className="update-container">
                <div className="img">
                  <img src={userdefult} className="user-logo" alt="user-logo" />
                </div>
                <div className="inputs">
                  <div className="input-container">
                    <label htmlFor="name">{t('updateProfile.name')}</label>
                    <input
                      name="name"
                      type="text"
                      value={name}
                      onChange={changeInput(setName)}
                      placeholder={t('updateProfile.name')}
                    />
                  </div>
                  {user && user.role === 'employee' && (
                    <div className="input-container">
                      <label htmlFor="lastname">
                        {t('updateProfile.lastname')}
                      </label>
                      <input
                        name="lastname"
                        type="text"
                        value={lastname}
                        onChange={changeInput(setLastname)}
                        placeholder={t('updateProfile.lastname')}
                      />
                    </div>
                  )}
                  <div className="input-container">
                    <label htmlFor="email">{t('updateProfile.email')}</label>
                    <input
                      name="email"
                      type="email"
                      value={email}
                      readOnly
                      onChange={changeInput(setEmail)}
                      placeholder={t('updateProfile.email')}
                    />
                  </div>
                  <div className="input-container">
                    <label htmlFor="password">
                      {t('updateProfile.password')}
                    </label>
                    <input
                      type="password"
                      value={password}
                      onChange={changeInput(setPassword)}
                      placeholder={t('updateProfile.password')}
                    />
                  </div>
                </div>
              </div>
              <div className="buttons">
                <button
                  type="button"
                  className="save"
                  disabled={!(name && lastname && email)}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {t('updateProfile.save')}
                </button>
              </div>
            </Col>
          </Row>
        </Col>
      </section>
    </div>
  );
};

UpdateProfile.propTypes = {
  actions: any.isRequired,
  user: object,
  updatedUser: string,
  updatedCompany: string,
};

UpdateProfile.defaultProps = {
  user: null,
  updatedUser: null,
  updatedCompany: null,
};
const mapStateToProps = (state) => {
  const { user } = state.user;
  const { updatedUser } = state.company;
  const { updatedCompany } = state.admin;

  return {
    user,
    updatedUser,
    updatedCompany,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(reduxActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile);
