import React from 'react';
import { useTranslation } from 'react-i18next';
import { func, string } from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';

import warning from '../../assets/image/warning.svg';

import './confirmComponent.scss';

const ConfirmComponent = ({ onCancel, onConfirm, confirmText }) => {
  const { t } = useTranslation();

  return (
    <div className="confirm-component">
      <Col xs={12}>
        <Row>
          <Col xs={12} className="center-content">
            <img src={warning} alt="warning" />
          </Col>
          <Col xs={12} className="center-content">
            <p>{confirmText}</p>
          </Col>
          <Col xs={12}>
            <div className="buttons-container">
              <button type="button" className="cancel" onClick={onCancel}>
                {t('global.no')}
              </button>
              <button type="button" onClick={onConfirm} className="save">
                {t('global.yes')}
              </button>
            </div>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

ConfirmComponent.propTypes = {
  onCancel: func.isRequired,
  onConfirm: func.isRequired,
  confirmText: string.isRequired,
};

export default ConfirmComponent;
