import React from 'react';
import { bool, any } from 'prop-types';
import { Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';

import * as reduxActions from './redux/actions';

import './App.scss';

import Header from './components/header/header';
import Footer from './components/footer/footer';
import Loading from './components/loading/loading';
import Modal from './components/modal/modal';
import PrivateRoute from './components/privateRoute/privateRoute';

import Home from './containers/home/home';
import UserInvestment from './containers/userInvestment/userInvestment';

import InvestAdmin from './containers/investAdmin/investAdmin';
import HomeAdmin from './containers/homeAdmin/homeAdmin';

import Login from './containers/login/login';
import HomeCompany from './containers/homeCompany/homeCompany';
import UpdateProfile from './containers/updateProfile/updateProfile';
import warning from './assets/image/warning.svg';

const App = ({ generalLoading, error, actions }) => {
  const { t } = useTranslation();

  const closeModal = () => {
    actions.closeError();
  };

  return (
    <>
      {generalLoading && <Loading />}
      {error && (
        <Modal className="xs">
          <div className="error-container">
            <Col xs={12}>
              <Row>
                <Col xs={12} className="center-content">
                  <img src={warning} alt="warning" />
                </Col>
                <Col xs={12} className="center-content">
                  <p>{error.text}</p>
                </Col>
                <Col xs={12}>
                  <div className="buttons-container">
                    <button
                      type="button"
                      className="cancel"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      {t('global.accept')}
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          </div>
        </Modal>
      )}
      <Header />
      <div className="container">
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/user-investment"
            element={
              <PrivateRoute>
                <UserInvestment />
              </PrivateRoute>
            }
          />
          <Route
            path="/home-company"
            element={
              <PrivateRoute>
                <HomeCompany />
              </PrivateRoute>
            }
          />
          <Route
            path="/update-profile"
            element={
              <PrivateRoute>
                <UpdateProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin-investment/:investmentClosingId"
            element={
              <PrivateRoute>
                <InvestAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="/home-admin"
            element={
              <PrivateRoute>
                <HomeAdmin />
              </PrivateRoute>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
};

App.propTypes = {
  actions: any.isRequired,
  generalLoading: bool.isRequired,
  error: any,
};

App.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => {
  const { error, generalLoading } = state.movement;

  return {
    error,
    generalLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(reduxActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
