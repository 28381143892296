import {
  RESET_ALL,
  SET_USERDATA,
  GET_USERDATA,
  SET_EMPLOYEE_SUMMARIES_DATA,
  SET_EMPLOYEE_DATA,
  SET_EMPLOYEE_INVESTMENT_DATA,
  RESET_EMPLOYEE_INVESTMENT_DATA,
  SET_EMPLOYEE_INVESTMENT_ACTIVE_DATA,
} from '../action-types';

const initialState = {
  user: null,
  employee: null,
  employeeSummariesData: null,
  employeeInvestment: null,
  employeeInvestmentActive: null,
};

// eslint-disable-next-line default-param-last
const users = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPLOYEE_SUMMARIES_DATA: {
      return {
        ...state,
        employeeSummariesData: action.employeeSummariesData,
      };
    }
    case SET_EMPLOYEE_DATA: {
      return {
        ...state,
        employee: action.employee,
      };
    }
    case SET_EMPLOYEE_INVESTMENT_DATA: {
      return {
        ...state,
        employeeInvestment: action.employeeInvestment,
      };
    }

    case SET_EMPLOYEE_INVESTMENT_ACTIVE_DATA: {
      return {
        ...state,
        employeeInvestmentActive: action.employeeInvestmentActive,
      };
    }

    case RESET_EMPLOYEE_INVESTMENT_DATA: {
      return {
        ...state,
        employeeInvestment: null,
      };
    }

    case SET_USERDATA: {
      return {
        ...state,
        user: action.user,
      };
    }

    case GET_USERDATA: {
      return {
        ...state,
        user: action.user,
      };
    }

    case RESET_ALL: {
      return initialState;
    }

    default:
      return state;
  }
};

export default users;
