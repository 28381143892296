import { authHeader, handleResponse } from '../../helpers';

export const createUser = ({
  name,
  lastname,
  email,
  monthlyAllowance,
  password,
}) => {
  const headers = authHeader();
  headers['Content-Type'] = 'application/json';
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({
      name,
      lastname,
      email,
      monthlyAllowance: parseFloat(monthlyAllowance),
      password,
      photoUrl: '',
    }),
    headers,
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}employees`,
    requestOptions
  ).then(handleResponse);
};
export const updateUser = (
  id,
  { name, lastname, email, monthlyAllowance, password }
) => {
  const headers = authHeader();
  headers['Content-Type'] = 'application/json';
  const requestOptions = {
    method: 'PUT',
    body: JSON.stringify({
      name,
      lastname,
      email,
      monthlyAllowance: parseFloat(monthlyAllowance),
      password,
      photoUrl: '',
    }),
    headers,
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}employees/${id}`,
    requestOptions
  ).then(handleResponse);
};

export const deleteUser = (id) => {
  const headers = authHeader();
  headers['Content-Type'] = 'application/json';
  const requestOptions = {
    method: 'PUT',
    body: JSON.stringify({
      deleted: 1,
    }),
    headers,
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}employees/${id}`,
    requestOptions
  ).then(handleResponse);
};

export const getUsers = ({ page }) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };

  const query = `?page=${page}&limit=20`;

  return fetch(
    `${process.env.REACT_APP_API_URL}employees${query}`,
    requestOptions
  ).then(handleResponse);
};
