import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-flexbox-grid';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { any, object } from 'prop-types';

import * as reduxActions from '../../redux/actions';

import AmountCard from '../../components/amountCard/amountCard';
import BalanceCard from '../../components/balanceCard/balanceCard';
import ProcessCard from '../../components/processCard/processCard';

import up from '../../assets/image/up.svg';
import down from '../../assets/image/down.svg';
import left from '../../assets/image/left.svg';
import right from '../../assets/image/right.svg';

import './home.scss';

const Home = ({
  actions,
  employeeSummariesData,
  user,
  employee,
  employeeInvestmentActive,
  cryptos,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  const ADMIN = 'admin';
  const COMPANY = 'company';
  const pageSize =
    (employeeSummariesData && employeeSummariesData.pageSize) || 0;
  const totalItems =
    (employeeSummariesData && employeeSummariesData.total) || 0;

  const numberOfPages = Math.ceil(totalItems / pageSize);

  const pages = Array.from(Array(numberOfPages || 1).keys());

  const getEmployeeSummaries = (newPage) => {
    actions.getEmployeeSummaries({ page: newPage });
  };

  useEffect(() => {
    actions.getUser();
  }, []);

  useEffect(() => {
    if (page) {
      getEmployeeSummaries(page);
    }
  }, [page]);

  useEffect(() => {
    if (user) {
      console.log('user.role', user.role);
      switch (user.role) {
        case ADMIN:
          navigate(`/home-admin`);
          break;
        case COMPANY:
          navigate(`/home-company`);
          break;
        default:
          break;
      }
      actions.getEmployee({ id: user.user.id });
      actions.getEmployeeInvestment();
      actions.getCryptos();
    }
  }, [user]);

  const getCripto = (id) => {
    const selectedCrypto = cryptos.docs.find(
      (crypto) => crypto.id === parseInt(id, 10)
    );
    return selectedCrypto;
  };

  return (
    <div className="home">
      <section className="hedings">
        <Col xs>
          <Row>
            <Col xs={12} lg={4} className="title-container">
              <h1>{t('home.hello')}</h1>
              <p className="sub-heading light">
                {t('home.welcomeUser')} {employee && employee.name}
              </p>
              <p className="description light">
                {t('home.checkYourInvestments')}
              </p>
              <button
                type="button"
                className="gradient"
                onClick={() => {
                  navigate(`/user-investment`);
                }}
              >
                {t('home.toInvest')}
              </button>
            </Col>
            <Col xs={12} lg={4} className="cards-container">
              {employee && (
                <AmountCard totalInvested={employee.totalInvested} />
              )}

              {employee && (
                <BalanceCard
                  amount={employee.availableBalance}
                  title={t('balanceCard.balanceAvailable')}
                />
              )}
            </Col>
            <Col xs={12} lg={4} className="cards-container">
              {employeeInvestmentActive &&
                Object.keys(employeeInvestmentActive).length > 0 && (
                  <ProcessCard amount={employeeInvestmentActive.amount} />
                )}
            </Col>
          </Row>
        </Col>
      </section>
      <section className="table-container">
        <h2>{t('home.investmentBalanceSheet')}</h2>
      </section>
      <div className="table-card">
        <table>
          <thead>
            <tr>
              <th>{t('home.table.cripto')}</th>
              <th>{t('home.table.currency')}</th>
              <th>{t('home.table.invested')}</th>
              <th>{t('home.table.price')}</th>
              <th>{t('home.table.rate')}</th>
              <th>{t('home.table.details')}</th>
            </tr>
          </thead>
          <tbody>
            {employeeSummariesData &&
              employeeSummariesData.docs &&
              cryptos &&
              employeeSummariesData.docs.map((employeeSummaryItem) => {
                const diference =
                  (getCripto(employeeSummaryItem.cryptoId).lastPrice /
                    getCripto(employeeSummaryItem.cryptoId).prevClosePrice) *
                    100 -
                  100;
                return (
                  <tr key={employeeSummaryItem.id}>
                    <td className="cripto-row">
                      <img
                        src={`https://s3.amazonaws.com/bulltor.fluvip.com/cryptos/${employeeSummaryItem.crytoCode}.svg`}
                        className="cripto-icon"
                        alt={employeeSummaryItem.crytoName}
                      />
                      <p>{employeeSummaryItem.crytoCode}</p>
                    </td>
                    <td>{employeeSummaryItem.crytoName}</td>
                    <td>
                      {employeeSummaryItem.amount.toFixed(8)}{' '}
                      {employeeSummaryItem.crytoCode}
                    </td>
                    <td>
                      ${' '}
                      {(
                        employeeSummaryItem.amount *
                        getCripto(employeeSummaryItem.cryptoId).lastPrice
                      ).toFixed(2)}
                    </td>
                    <td className={diference > 0 ? 'green' : 'red'}>
                      <div className="rate-container">
                        {diference > 0 && (
                          <img src={up} className="status-icon" alt="up" />
                        )}
                        {diference < 0 && (
                          <img src={down} className="status-icon" alt="down" />
                        )}{' '}
                        {diference.toFixed(2)}%
                      </div>
                    </td>
                    <td>
                      <button type="button" className="outline">
                        {t('home.table.balance')}
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {employeeSummariesData && employeeSummariesData.total > 0 && (
        <div className="paginator">
          {page > 1 && (
            <img
              src={left}
              className="left"
              alt="left"
              onClick={() => {
                setPage(page - 1);
              }}
              aria-hidden="true"
            />
          )}
          {pages.map((pageItem) => {
            return (
              <div
                key={pageItem}
                className={`item ${pageItem + 1 === page ? 'selected' : ''}`}
                onClick={() => {
                  if (pageItem + 1 !== page) {
                    setPage(pageItem + 1);
                  }
                }}
                aria-hidden="true"
              >
                {pageItem + 1}
              </div>
            );
          })}
          {page < numberOfPages && (
            <img
              src={right}
              className="right"
              alt="right"
              onClick={() => {
                setPage(page + 1);
              }}
              aria-hidden="true"
            />
          )}
        </div>
      )}
    </div>
  );
};

Home.propTypes = {
  actions: any.isRequired,
  user: object,
  employee: object,
  employeeSummariesData: object,
  employeeInvestmentActive: object,
  cryptos: object,
};

Home.defaultProps = {
  user: null,
  employee: null,
  employeeSummariesData: null,
  cryptos: null,
  employeeInvestmentActive: null,
};
const mapStateToProps = (state) => {
  const { user, employeeSummariesData, employee, employeeInvestmentActive } =
    state.user;
  const { cryptos } = state.crypto;

  return {
    user,
    employee,
    employeeSummariesData,
    employeeInvestmentActive,
    cryptos,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(reduxActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
