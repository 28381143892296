import { RESET_ALL, SET_CRYTOS_DATA } from '../action-types';

const initialState = {
  user: null,
  cryptos: null,
};

// eslint-disable-next-line default-param-last
const users = (state = initialState, action) => {
  switch (action.type) {
    case SET_CRYTOS_DATA: {
      return {
        ...state,
        cryptos: action.crytos,
      };
    }

    case RESET_ALL: {
      return initialState;
    }

    default:
      return state;
  }
};

export default users;
