import {
  RESET_ALL,
  SET_NEW_COMPANY,
  SET_COMPANY_DATA,
  SET_INVESTMENT_CLOSINGS_DATA,
  SET_INVESTMENT_CLOSING_DATA,
  SET_UPDATED_COMPANY,
  SET_DELETED_COMPANY,
  CLOSE_INVESTMENT_CLOSING,
  SET_CLOSING_SUMMARIES_DATA,
  SET_INVEST_INVESTMENT_CLOSING,
} from '../action-types';

const initialState = {
  newCompany: null,
  updatedCompany: null,
  deletedCompany: null,
  companiesData: null,
  investmentData: null,
  investmentClosingData: null,
  closeInvestmentClosingData: null,
  closingSummariesData: null,
  investInvestmentClosingData: null,
};

// eslint-disable-next-line default-param-last
const companies = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_COMPANY: {
      return {
        ...state,
        newCompany: action.newCompany,
      };
    }

    case SET_UPDATED_COMPANY: {
      return {
        ...state,
        updatedCompany: action.updatedCompany,
      };
    }

    case SET_DELETED_COMPANY: {
      return {
        ...state,
        deletedCompany: action.deletedCompany,
      };
    }

    case SET_COMPANY_DATA: {
      return {
        ...state,
        companiesData: action.companiesData,
      };
    }

    case SET_INVESTMENT_CLOSINGS_DATA: {
      return {
        ...state,
        investmentData: action.investmentClosingsData,
      };
    }

    case SET_INVESTMENT_CLOSING_DATA: {
      return {
        ...state,
        investmentClosingData: action.investmentClosingData,
      };
    }

    case CLOSE_INVESTMENT_CLOSING: {
      return {
        ...state,
        closeInvestmentClosingData: action.closeInvestmentClosingData,
      };
    }

    case SET_CLOSING_SUMMARIES_DATA: {
      return {
        ...state,
        closingSummariesData: action.closingSummariesData,
      };
    }

    case SET_INVEST_INVESTMENT_CLOSING: {
      return {
        ...state,
        investInvestmentClosingData: action.investInvestmentClosingData,
      };
    }

    case RESET_ALL: {
      return initialState;
    }

    default:
      return state;
  }
};

export default companies;
