import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-flexbox-grid';
import { number } from 'prop-types';

import './retirementDate.scss';

const RetirementDate = ({ months, weeks, days }) => {
  const { t } = useTranslation();

  return (
    <div className="retirement-date">
      <Col xs={12}>
        <Row>
          <Col xs={12} className="retirement-date_title-container">
            <span className="retirement-date_title">
              {t('retirementDate.nextRetreat')}
            </span>
          </Col>
          <Col xs={12} className="retirement-date_date-container">
            <Row>
              <Col xs={4} className="retirement-date_date-title">
                {t('retirementDate.months')}
              </Col>
              <Col xs={4} className="retirement-date_date-title">
                {t('retirementDate.weeks')}
              </Col>
              <Col xs={4} className="retirement-date_date-title">
                {t('retirementDate.days')}
              </Col>
            </Row>
            <Row>
              <Col xs={4} className="retirement-date_date-description">
                {months}
              </Col>
              <Col xs={4} className="retirement-date_date-description">
                {weeks}
              </Col>
              <Col xs={4} className="retirement-date_date-description">
                {days}
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="button-warning-container">
            <button
              type="button"
              className="button-warning"
              disabled={days > 1}
            >
              {t('retirementDate.withdraw')}
            </button>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

RetirementDate.propTypes = {
  months: number,
  weeks: number,
  days: number,
};

RetirementDate.defaultProps = {
  months: 0,
  weeks: 0,
  days: 0,
};

export default RetirementDate;
