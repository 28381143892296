import React from 'react';
import { Navigate } from 'react-router-dom';
import { node } from 'prop-types';

const PrivateRoute = ({ children }) => {
  if (!localStorage.getItem('user')) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

PrivateRoute.propTypes = {
  children: node.isRequired,
};

export default PrivateRoute;
