import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { any, object } from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import * as reduxActions from '../../redux/actions';

import BalanceCard from '../../components/balanceCard/balanceCard';
import RetirementDate from '../../components/retirementDate/retirementDate';
import GoBack from '../../components/goBack/goBack';

import titleIcon from '../../assets/image/investmentusericon.svg';
import addicon from '../../assets/image/addicon.svg';
import removeicon from '../../assets/image/removeicon.svg';

import './userInvestment.scss';

const UserInvestment = ({
  actions,
  user,
  employee,
  cryptos,
  employeeInvestment,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [employeeInvestmentDetails, setEmployeeInvestmentDetails] = useState(
    []
  );

  useEffect(() => {
    actions.getUser();
  }, []);

  useEffect(() => {
    if (user) {
      actions.getEmployee({ id: user.user.id });
      actions.getCryptos();
    }
  }, [user]);

  useEffect(() => {
    if (employeeInvestment) {
      actions.clearEmployeeInvestment();
      navigate(-1);
    }
  }, [employeeInvestment]);

  const getNextWithdrawal = (nextWithdrawal) => {
    const now = moment();
    const nextWithdrawalMoment = moment(nextWithdrawal);
    return [
      nextWithdrawalMoment.diff(now, 'months'),
      nextWithdrawalMoment.diff(now, 'weeks'),
      nextWithdrawalMoment.diff(now, 'days'),
    ];
  };

  const getCripto = (id) => {
    const selectedCrypto = cryptos.docs.find(
      (crypto) => crypto.id === parseInt(id, 10)
    );
    return selectedCrypto;
  };

  const changeInput =
    (index, fieldName, isFloat = false) =>
    (event) => {
      const newEmployeeInvestmentDetails = [...employeeInvestmentDetails];
      const { value } = event.target;
      newEmployeeInvestmentDetails[index][fieldName] = isFloat
        ? parseFloat(value || 0)
        : value;
      setEmployeeInvestmentDetails(newEmployeeInvestmentDetails);
    };

  const addEmployeeInvestmentDetail = () => {
    const newEmployeeInvestmentDetails = [...employeeInvestmentDetails];
    newEmployeeInvestmentDetails.push({ cryptoId: null, amount: 0 });
    setEmployeeInvestmentDetails(newEmployeeInvestmentDetails);
  };

  const removeEmployeeInvestmentDetail = () => {
    const newEmployeeInvestmentDetails = [...employeeInvestmentDetails];
    newEmployeeInvestmentDetails.pop();
    setEmployeeInvestmentDetails(newEmployeeInvestmentDetails);
  };

  const totalAmount = employeeInvestmentDetails.length
    ? employeeInvestmentDetails.reduce((acc, item) => {
        return acc + item.amount;
      }, 0)
    : 0;

  const isValid = () => {
    let valid = true;
    if (employeeInvestmentDetails.length === 0) {
      valid = false;
    }

    employeeInvestmentDetails.forEach((employeeInvestmentDetail) => {
      if (
        !(
          employeeInvestmentDetail.amount && employeeInvestmentDetail.amount > 0
        )
      ) {
        valid = false;
      }
      if (!employeeInvestmentDetail.cryptoId) {
        valid = false;
      }
    });

    if (!employee || totalAmount > employee.availableBalance) {
      valid = false;
    }

    return valid;
  };

  const invest = () => {
    const newEmployeeInvestmentDetails = employeeInvestmentDetails.map(
      (employeeInvestmentDetail) => {
        const { amount, cryptoId } = employeeInvestmentDetail;
        const { name, code } = getCripto(employeeInvestmentDetail.cryptoId);
        return {
          cryptoId,
          crytoName: name,
          crytoCode: code,
          amount,
        };
      }
    );
    const employeeInvestmentBody = {
      employeeInvestmentDetails: newEmployeeInvestmentDetails,
    };
    actions.createEmployeeInvestment(employeeInvestmentBody);
  };

  return (
    <div className="user-investment">
      <GoBack />
      <section className="hedings">
        <Col xs>
          <Row>
            <Col xs={12} lg={6} className="title-container">
              <h1>{t('userInvestment.Invest')}</h1>
              <h2 className="sub-heading">
                {t('userInvestment.manageYourMonthly')}
              </h2>
            </Col>
            <Col xs={12} lg={6} className="title-icon-container">
              <img src={titleIcon} className="title-icon" alt="title Icon" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6} className="balance-container">
              <Row>
                <Col xs={12}>
                  {employee && (
                    <BalanceCard
                      amount={employee.availableBalance}
                      title={t('balanceCard.balanceAvailable')}
                    />
                  )}
                </Col>
                <Col xs={12}>
                  <p className="warning light">
                    {t('userInvestment.warning')}:
                  </p>
                  <p className="warning-text">
                    {t('userInvestment.theRateShown')}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={6} className="title-icon-container">
              <div className="title-icon-container-normalizer">
                {employee && employee.nextWithdrawal && (
                  <RetirementDate
                    months={getNextWithdrawal(employee.nextWithdrawal)[0]}
                    weeks={getNextWithdrawal(employee.nextWithdrawal)[1]}
                    days={getNextWithdrawal(employee.nextWithdrawal)[2]}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </section>
      <section className="table-container">
        <h2>{t('userInvestment.investmentManagement')}</h2>
      </section>
      <div className="table-card">
        <table>
          <thead>
            <tr>
              <th>{t('userInvestment.table.criptoCurrency')}</th>
              <th>{t('userInvestment.table.price')}</th>
              <th>{t('userInvestment.table.iInvest')}</th>
              <th>{t('userInvestment.table.receipt')}</th>
            </tr>
          </thead>
          <tbody>
            {employeeInvestmentDetails.length > 0 &&
              cryptos &&
              employeeInvestmentDetails.map(
                (employeeInvestmentDetail, index) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={`employeeInvestmentDetail-${index}`}>
                      <td>
                        <select
                          className="cripto-select"
                          name={`criptoCurrency-${index}`}
                          id={`criptoCurrency-${index}`}
                          value={employeeInvestmentDetail.cryptoId}
                          onChange={changeInput(index, 'cryptoId', true)}
                        >
                          <option
                            defaultValue
                            // eslint-disable-next-line react/no-array-index-key
                            key={`criptoCurrency-${index}-default`}
                            value={null}
                          >
                            -
                          </option>
                          {cryptos.docs.map((cryptoItem) => {
                            return (
                              <option
                                // eslint-disable-next-line react/no-array-index-key
                                key={`criptoCurrency-${index}-${cryptoItem.id}`}
                                value={cryptoItem.id}
                              >
                                {cryptoItem.name} - {cryptoItem.code}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td>
                        {employeeInvestmentDetail.cryptoId && (
                          <>
                            ${' '}
                            {
                              getCripto(employeeInvestmentDetail.cryptoId)
                                .lastPrice
                            }{' '}
                            <span className="flag-currency">USD</span>
                          </>
                        )}
                      </td>
                      <td>
                        <div className="td-container">
                          <div className="input-container">
                            <input
                              value={employeeInvestmentDetail.amount}
                              onChange={changeInput(index, 'amount', true)}
                            />
                            <span className="flag-currency">USD</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        {employeeInvestmentDetail.amount > 0 &&
                          employeeInvestmentDetail.cryptoId && (
                            <>
                              {(
                                employeeInvestmentDetail.amount /
                                getCripto(employeeInvestmentDetail.cryptoId)
                                  .lastPrice
                              ).toFixed(8)}{' '}
                              <span className="flag-currency">
                                {
                                  getCripto(employeeInvestmentDetail.cryptoId)
                                    .code
                                }
                              </span>
                            </>
                          )}
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </table>
        <div className="add-container">
          <img
            src={addicon}
            className="add-rm-icon"
            alt="addicon"
            onClick={() => {
              addEmployeeInvestmentDetail();
            }}
            aria-hidden="true"
          />
          <img
            src={removeicon}
            className="add-rm-icon"
            alt="removeicon"
            onClick={() => {
              removeEmployeeInvestmentDetail();
            }}
            aria-hidden="true"
          />
        </div>
        <div className="action-container">
          {/* <button type="button" className="button-cancel">
            {t('userInvestment.cancel')}
          </button> */}
          <button
            type="button"
            disabled={!isValid()}
            className="button-warning"
            onClick={() => {
              invest();
            }}
          >
            {t('userInvestment.invest')}
          </button>
        </div>
      </div>
    </div>
  );
};

UserInvestment.propTypes = {
  actions: any.isRequired,
  user: object,
  employee: object,
  cryptos: object,
  employeeInvestment: object,
};

UserInvestment.defaultProps = {
  user: null,
  employee: null,
  cryptos: null,
  employeeInvestment: null,
};
const mapStateToProps = (state) => {
  const { user, employee, employeeInvestment } = state.user;
  const { cryptos } = state.crypto;

  return {
    user,
    employee,
    cryptos,
    employeeInvestment,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(reduxActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInvestment);
