import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';

import './createUser.scss';

const CreateUser = ({ onCancel, onCreate }) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [lastname, setlastName] = useState('');
  const [email, setEmail] = useState('');
  const [monthlyAllowance, setMonthlyAllowance] = useState('');
  const [password, setPassword] = useState('');

  const changeInput = (inputSet) => (event) => {
    inputSet(event.target.value);
  };

  const handleSubmit = () => {
    onCreate({
      name,
      lastname,
      email,
      monthlyAllowance,
      password,
    });
  };

  return (
    <div className="create-user">
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <h1>{t('createUser.createUser')}</h1>
          </Col>
          <Col xs={12} lg={4} lgOffset={2}>
            <div className="input-container">
              <label htmlFor="name">{t('createUser.name')}</label>
              <input
                type="text"
                value={name}
                onChange={changeInput(setName)}
                placeholder={t('createUser.name')}
              />
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className="input-container">
              <label htmlFor="lastname">{t('createUser.lastname')}</label>
              <input
                type="text"
                value={lastname}
                onChange={changeInput(setlastName)}
                placeholder={t('createUser.lastname')}
              />
            </div>
          </Col>
          <Col xs={12} lg={4} lgOffset={2}>
            <div className="input-container">
              <label htmlFor="email">{t('createUser.email')}</label>
              <input
                type="email"
                value={email}
                onChange={changeInput(setEmail)}
                placeholder={t('createUser.email')}
              />
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className="input-container">
              <label htmlFor="monthlyAllowance">
                {t('createUser.monthlyAllowance')}
              </label>
              <input
                type="number"
                value={monthlyAllowance}
                onChange={changeInput(setMonthlyAllowance)}
                placeholder={t('createUser.monthlyAllowance')}
              />
            </div>
          </Col>
          <Col xs={12} lg={8} lgOffset={2}>
            <div className="input-container">
              <label htmlFor="password">{t('createUser.password')}</label>
              <input
                type="password"
                value={password}
                onChange={changeInput(setPassword)}
                placeholder={t('createUser.password')}
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="buttons-container">
              <button type="button" className="cancel" onClick={onCancel}>
                {t('updateProfile.cancel')}
              </button>
              <button
                type="button"
                disabled={
                  !(name && lastname && email && monthlyAllowance && password)
                }
                onClick={() => {
                  handleSubmit();
                }}
                className="save"
              >
                {t('updateProfile.save')}
              </button>
            </div>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

CreateUser.propTypes = {
  onCancel: func.isRequired,
  onCreate: func.isRequired,
};

export default CreateUser;
