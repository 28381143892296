import * as adminService from '../services/admin.service';
import {
  GENERAL_ERROR,
  SHOW_GENERAL_LOADING,
  HIDE_GENERAL_LOADING,
  SET_NEW_COMPANY,
  SET_COMPANY_DATA,
  SET_INVESTMENT_CLOSINGS_DATA,
  SET_INVESTMENT_CLOSING_DATA,
  SET_UPDATED_COMPANY,
  SET_DELETED_COMPANY,
  CLOSE_INVESTMENT_CLOSING,
  SET_CLOSING_SUMMARIES_DATA,
  SET_INVEST_INVESTMENT_CLOSING,
} from '../action-types';

const generalError = (error) => {
  return { type: GENERAL_ERROR, error };
};

const showLoading = () => {
  return { type: SHOW_GENERAL_LOADING };
};

const hideLoading = () => {
  return { type: HIDE_GENERAL_LOADING };
};

const setNewCompany = (newCompany) => {
  return { type: SET_NEW_COMPANY, newCompany };
};

const setUpdatedCompany = (updatedCompany) => {
  return { type: SET_UPDATED_COMPANY, updatedCompany };
};

const setDeletedCompany = (deletedCompany) => {
  return { type: SET_DELETED_COMPANY, deletedCompany };
};

const setCompanies = (companiesData) => {
  return { type: SET_COMPANY_DATA, companiesData };
};

const setInvestmentClosings = (investmentClosingsData) => {
  return { type: SET_INVESTMENT_CLOSINGS_DATA, investmentClosingsData };
};

const setInvestmentClosing = (investmentClosingData) => {
  return { type: SET_INVESTMENT_CLOSING_DATA, investmentClosingData };
};

const closeInvestmentClosing = (closeInvestmentClosingData) => {
  return { type: CLOSE_INVESTMENT_CLOSING, closeInvestmentClosingData };
};

const setInvestInvestmentClosing = (investInvestmentClosingData) => {
  return { type: SET_INVEST_INVESTMENT_CLOSING, investInvestmentClosingData };
};

const setClosingSummaries = (closingSummariesData) => {
  return { type: SET_CLOSING_SUMMARIES_DATA, closingSummariesData };
};

export const createCompany = ({
  name,
  email,
  userLimit,
  withdrawalDeadline,
  password,
}) => {
  return (dispatch) => {
    dispatch(showLoading());
    adminService
      .createCompany({
        name,
        userLimit,
        email,
        withdrawalDeadline,
        password,
      })
      .then(
        (body) => {
          const { data } = body;
          dispatch(setNewCompany(data));
          dispatch(hideLoading());
        },
        (error) => {
          dispatch(generalError(error));
          dispatch(hideLoading(error));
        }
      );
  };
};

export const updateCompany = (
  id,
  { name, userLimit, email, withdrawalDeadline, password }
) => {
  return (dispatch) => {
    dispatch(showLoading());
    adminService
      .updateCompany(id, {
        name,
        userLimit,
        email,
        withdrawalDeadline,
        password,
      })
      .then(
        (body) => {
          const { data } = body;
          dispatch(setUpdatedCompany(data));
          dispatch(hideLoading());
        },
        (error) => {
          dispatch(generalError(error));
          dispatch(hideLoading(error));
        }
      );
  };
};

export const payCompany = (id) => {
  return (dispatch) => {
    dispatch(showLoading());
    adminService.payCompany(id).then(
      () => {
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};

export const deleteCompany = (id) => {
  return (dispatch) => {
    dispatch(showLoading());
    adminService.deleteCompany(id).then(
      (body) => {
        const { data } = body;
        dispatch(setDeletedCompany(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};

export const getCompanies = ({ page }) => {
  return (dispatch) => {
    dispatch(showLoading());
    adminService.getCompanies({ page }).then(
      (body) => {
        const { data } = body;
        dispatch(setCompanies(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};

export const getInvestmentClosings = ({ page }) => {
  return (dispatch) => {
    dispatch(showLoading());
    adminService.getInvestmentClosings({ page }).then(
      (body) => {
        const { data } = body;
        dispatch(setInvestmentClosings(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};

export const getInvestmentClosing = ({ id }) => {
  return (dispatch) => {
    dispatch(showLoading());
    adminService.getInvestmentClosing({ id }).then(
      (body) => {
        const { data } = body;
        dispatch(setInvestmentClosing(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};

export const updateInvestmentClosing = ({ id }) => {
  return (dispatch) => {
    dispatch(showLoading());
    adminService.updateInvestmentClosing({ id }).then(
      (body) => {
        const { data } = body;
        dispatch(closeInvestmentClosing(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};

export const investInvestmentClosing = ({ id, investBody }) => {
  return (dispatch) => {
    dispatch(showLoading());
    adminService.investInvestmentClosing({ id, investBody }).then(
      (body) => {
        const { data } = body;
        dispatch(setInvestInvestmentClosing(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};

export const getClosingSummaries = ({ id }) => {
  return (dispatch) => {
    dispatch(showLoading());
    adminService.getClosingSummaries({ id }).then(
      (body) => {
        const { data } = body;
        dispatch(setClosingSummaries(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};

export const cleanUpdatedCompany = () => {
  return (dispatch) => {
    dispatch(setUpdatedCompany(null));
  };
};

export const cleanDeletedCompany = () => {
  return (dispatch) => {
    dispatch(setDeletedCompany(null));
  };
};

export const cleanCloseInvestmentClosing = () => {
  return (dispatch) => {
    dispatch(closeInvestmentClosing(null));
  };
};

export const cleanInvestInvestmentClosing = () => {
  return (dispatch) => {
    dispatch(setInvestInvestmentClosing(null));
  };
};
