import * as userService from '../services/user.service';
import {
  SET_USERDATA,
  GET_USERDATA,
  GENERAL_ERROR,
  SHOW_GENERAL_LOADING,
  HIDE_GENERAL_LOADING,
  SET_EMPLOYEE_SUMMARIES_DATA,
  SET_EMPLOYEE_DATA,
  SET_EMPLOYEE_INVESTMENT_DATA,
  RESET_EMPLOYEE_INVESTMENT_DATA,
  SET_EMPLOYEE_INVESTMENT_ACTIVE_DATA,
} from '../action-types';

const generalError = (error) => {
  return { type: GENERAL_ERROR, error };
};

const showLoading = () => {
  return { type: SHOW_GENERAL_LOADING };
};
const hideLoading = () => {
  return { type: HIDE_GENERAL_LOADING };
};

const setEmployeeSummaries = (employeeSummariesData) => {
  return { type: SET_EMPLOYEE_SUMMARIES_DATA, employeeSummariesData };
};

const setEmployee = (employee) => {
  return { type: SET_EMPLOYEE_DATA, employee };
};

const setEmployeeInvestmentActive = (employeeInvestmentActive) => {
  return {
    type: SET_EMPLOYEE_INVESTMENT_ACTIVE_DATA,
    employeeInvestmentActive,
  };
};

const setEmployeeInvestment = (employeeInvestment) => {
  return { type: SET_EMPLOYEE_INVESTMENT_DATA, employeeInvestment };
};

const resetEmployeeInvestment = () => {
  return { type: RESET_EMPLOYEE_INVESTMENT_DATA };
};

export const login = ({ email, password }) => {
  function setUser(user) {
    return { type: SET_USERDATA, user };
  }
  return (dispatch) => {
    dispatch(showLoading());
    userService.login({ email, password }).then(
      (body) => {
        const { data } = body;
        const { token } = data;
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(data));
        dispatch(setUser(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};

export const closeError = () => {
  return (dispatch) => {
    dispatch(generalError(null));
  };
};

export const setUser = ({ token, user }) => {
  function success(newUser) {
    return { type: SET_USERDATA, user: newUser };
  }
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));
  return (dispatch) => {
    dispatch(success(user));
  };
};

export const getUser = () => {
  function success(user) {
    return { type: GET_USERDATA, user };
  }
  const user = JSON.parse(localStorage.getItem('user'));
  return (dispatch) => {
    dispatch(success(user));
  };
};

export const getEmployeeSummaries = ({ page }) => {
  return (dispatch) => {
    dispatch(showLoading());
    userService.getEmployeeSummaries({ page }).then(
      (body) => {
        const { data } = body;
        dispatch(setEmployeeSummaries(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};

export const getEmployee = ({ id }) => {
  return (dispatch) => {
    dispatch(showLoading());
    userService.getEmployee({ id }).then(
      (body) => {
        const { data } = body;
        dispatch(setEmployee(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};

export const getEmployeeInvestment = () => {
  return (dispatch) => {
    dispatch(showLoading());
    userService.getEmployeeInvestment(getEmployeeInvestment).then(
      (body) => {
        const { data } = body;
        dispatch(setEmployeeInvestmentActive(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};

export const createEmployeeInvestment = (employeeInvestmentBody) => {
  return (dispatch) => {
    dispatch(showLoading());
    userService.createEmployeeInvestment(employeeInvestmentBody).then(
      (body) => {
        const { data } = body;
        dispatch(setEmployeeInvestment(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};

export const clearEmployeeInvestment = () => {
  return (dispatch) => {
    dispatch(resetEmployeeInvestment());
  };
};
